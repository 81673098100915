import './App.css';
import React, { useEffect, useState, useCallback } from 'react';
import { AuthProvider, Descope } from '@descope-int/react-dynamic-sdk';
import Particles from 'react-particles';
import type { Engine } from 'tsparticles-engine';
import { loadFull } from 'tsparticles';
import { ISourceOptions } from 'tsparticles-engine';
import userflow from 'userflow.js';
import particlesOptions from './particles.json';
import Welcome from './components/Welcome';

const projectRegex = /^P[a-zA-Z0-9]{27}$/;

const getV2Config = (projectId: string, cb: (res: any) => void) => {
	const baseUrl =
		window.localStorage.getItem('base.content.url') ||
		'https://static.descope.com/pages';
	fetch(`${baseUrl}/${projectId}/v2-beta/config.json`).then((res) =>
		cb(res.ok)
	);
};

const userId = 'login_screen';

userflow.init('ct_srfntmdlkvbcdgqrcqqrekolu4');
userflow.identify(userId, {});

const App = () => {
	const baseUrl = 'https://ds.auth.bot';

	const baseProjectId = `P2YZd9kq5zrYRgwGqZ3Vmqkt3D2Y`;

	const particlesInit = useCallback(async (engine: Engine) => {
		await loadFull(engine);
	}, []);

	const [error, setError] = useState(false);

	// If exists in URI use it, otherwise use env
	const pathnameProjectId = projectRegex.exec(
		window.location.pathname?.split('/').at(-1) || ''
	)?.[0];
	
	const projectId = pathnameProjectId ?? baseProjectId ?? '';

	const [isV2, setIsV2] = useState(null);

	useEffect(() => {
		getV2Config(projectId, (success) => {
			setIsV2(success);
		});
	}, [projectId]);

	if (isV2 === null) {
		return null;
	}

	const urlParams = new URLSearchParams(window.location.search);

	const flowId =
		urlParams.get('f') ||
		urlParams.get('flow') ||
		urlParams.get('flowid') ||
		'passkeys-web-sign-up-or-in';

	const debug =
		urlParams.get('debug') === 'true' || urlParams.get('d') === 'true' || false;

	const tenantId = urlParams.get('tenant') || urlParams.get('t') || '';

	const backgroundColor =
		urlParams.get('b') ||
		urlParams.get('bg') ||
		urlParams.get('background') ||
		'';

	return (
		<AuthProvider
			projectId={projectId}
			baseUrl={baseUrl}
			sdkVersion={isV2 ? 'v2' : 'v1'}
		>
			<div className="app" style={{ backgroundColor }}>
				<Particles
					options={particlesOptions as ISourceOptions}
					init={particlesInit}
				/>

				<div className="hidden">{baseUrl}</div>
				<div className="hidden">{projectId}</div>
				<div className="hidden">{tenantId}</div>

				{projectId && flowId && !error ? (
					<div className="descope-container" data-testid="descope-component">
						<Descope
							flowId={flowId}
							debug={debug}
							tenant={tenantId}
							onError={() => setError(true)}
						/>
					</div>
				) : (
					<Welcome />
				)}
			</div>
		</AuthProvider>
	);
};

export default App;
